import React from 'react';
import VideoContainer from "../components/VideoContainer.js";
import "../styles/Videos.scss";

const Videos = props => {

    const videos = [
        {
            id: 0,
            source: "Kql_TgbBf1c",
            category: "music videos",
            title: "The Angelus - The Other Side of the Mountain (Official Video)"
        },
        {
            id: 1,
            source: "gvkWFyP5hnU",
            category: "music videos",
            title: "The Angelus - As I Live and Breathe (Official Video)"
        },
        {
            id: 2,
            source: "PLYF_HABuAQ",
            category: "music videos",
            title: "The Angelus - There Will Be No Peace (Official Video)"
        },
        {
            id: 3,
            source: "6cVl8DtLCp0",
            category: "music videos",
            title: "The Angelus - Crimson Shadow (Official Video)"
        },
        {
            id: 4,
            source: "6-VhNtun-cM",
            category: "miscellaneous videos",
            title: "The Angelus - All is Well"
        },
        {
            id: 5,
            source: "yI89osoia9k",
            category: "miscellaneous videos",
            title: "The Angelus - The Other Side of the Mountain (Official Video)"
        },
        {
            id: 6,
            source: "ujBCHJX5Nj0",
            category: "miscellaneous videos",
            title: "The Angelus - Another Kind"
        }
    ]

    return (
        <div className="videos">
            <h2>Music Videos</h2>
            {videos.filter(video => video.category === "music videos")
                .map(video => {
                    return (
                        <VideoContainer
                            source={video.source}
                            title={video.title}
                            key={video.id}
                            videoSize="video-container-16x9"
                        />
                    )
                }
                )}
            <h2>Miscellaneous Videos</h2>
            {videos.filter(video => video.category === "miscellaneous videos")
                .map(video => {
                    return (
                        <VideoContainer
                            source={video.source}
                            title={video.title}
                            key={video.id}
                            videoSize="video-container-16x9"
                        />
                    )
                }
                )}
            {props.children}
        </div>
    )
}

export default Videos;