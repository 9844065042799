import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Wrapper from "./components/Wrapper.js";
import Header from "./components/Header.js";
import Nav from "./components/Nav.js";
import Main from "./components/Main.js";
import Footer from "./components/Footer.js";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Shows from "./pages/Shows.js";
import Media from "./pages/Media.js";
import Store from "./pages/Store.js";
import Press from "./pages/Press.js";
import Contact from "./pages/Contact.js";
import NoMatch from "./pages/NoMatch.js";
import "./App.scss";

function App() {

  const [headerHeight, setHeaderHeight] = useState(60);

  return (
    <div className="App">
      <Router>
        <Wrapper>
          <Header
            setHeaderHeight={setHeaderHeight}
          />
          <Nav
            headerHeight={headerHeight}
          />
          <Main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/shows" component={Shows} />
              <Route exact path="/media" component={Media} />
              <Route exact path="/store" component={Store} />
              <Route exact path="/press" component={Press} />
              <Route exact path="/contact" component={Contact} />
              <Route component={NoMatch} />
            </Switch>
          </Main>
          <Footer />
        </Wrapper>
      </Router>
    </div>
  );
}

export default App;
