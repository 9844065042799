import React, { useEffect } from 'react';

const NoMatch = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="nomatch">
            <p className="center">Hmm, we can't seem to find that page.</p>
            <p className="center">Try one of the links in the navigation menu.</p>
           {props.children}
        </div>
    )
}

export default NoMatch;