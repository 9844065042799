import React from 'react';
import "../styles/BandsInTown.scss";

const BandsInTown = props => {

    return (
        <div className="bandsintown">
            <div id="tourtracker">
                <a className="bit-widget-initializer" data-artist-name="The Angelus" data-display-local-dates="true" data-display-past-dates="true" data-auto-style="false" data-text-color="#FFFFFF" data-link-color="#cc9e4c" data-popup-background-color="rgba(0,0,0,0)" data-background-color="rgba(0,0,0,0)" data-display-limit="15" data-link-text-color="#FFFFFF" href="/shows">Load Show Dates</a>
            </div>

            {props.children}
        </div>
    )
}


export default BandsInTown;