import React from 'react';
import MusicModule from "./MusicModule.js";
import "../styles/Music.scss";

const Music = props => {

    const music = [
        {
            id: 0,
            title: "Why We Never Die",
            source: "3928851053"
        },
        {
            id: 1,
            title: "The Young Birds / The Weeping Song",
            source: "141510258"
        },
        {
            id: 2,
            title: "There Will Be No Peace",
            source: "1895855950"
        },
        {
            id: 3,
            title: "On A Dark & Barren Land",
            source: "3767469239"
        }
    ]

    return (
        <div className="music">
            {music.map(album => {
                return (
                    <MusicModule
                        key={album.id}
                        source={album.source}
                        title={album.title}
                    />
                )
            })}
            
           {props.children}
        </div>
    )
}

export default Music;