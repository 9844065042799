import React from 'react';
import "../styles/MusicModule.scss";

const Music = props => {

    const { source, title } = props;

    return (
        <div className="music-module">
            <iframe src={`https://bandcamp.com/EmbeddedPlayer/album=${source}/size=large/bgcol=000000/linkcol=ffffff/tracklist=false/transparent=true/`} title={title}></iframe>
            {props.children}
        </div>
    )
}

export default Music;