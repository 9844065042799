import React from 'react';
import "../styles/Merch.scss";
import Ram from "../images/ram.jpg";
import Dove from "../images/dove.jpg";
import Owl from "../images/owl.jpg";
import Pin from "../images/pin.jpg";

const Merch = props => {

    const merch = [
        {
            id: 0,
            title: "Dove T-Shirt",
            source: "there-will-be-no-peace-t-shirt",
            imgSrc: Dove
        },
        {
            id: 1,
            title: "Owl T-Shirt",
            source: "the-angelus-owl-t-shirt-black",
            imgSrc: Owl
        },
        {
            id: 2,
            title: "Ram T-Shirt",
            source: "the-angelus-ram-t-shirt-black",
            imgSrc: Ram
        },
        {
            id: 3,
            title: "1 Inch Enamel Pin",
            source: "there-will-be-no-peace-1-enamel-pin",
            imgSrc: Pin
        }
    ]

    return (
        <div className="merch">
            {merch.map(item => {
                return (
                    <div className="merch-module" key={item.id}>
                        <h3 className="center">{item.title}</h3>
                        <a href={`https://theangelus.bandcamp.com/merch/${item.source}`}target="_blank" rel="noreferrer noopener">
                            <img className="center merchitem" src={item.imgSrc} alt={item.title} />
                        </a>
                    </div>
                )
            })}
            
           {props.children}
        </div>
    )
}

export default Merch;