import React from 'react';
import "../styles/SocialNav.scss";

const SocialNav = props => {

    const { icons } = props;

    return (
        <div className="socialnav">
        {icons.map(icon => {
            return (
                <a href={icon.source} key={icon.id} target="_blank" rel="noreferrer noopener">
                    <i className={icon.iconClass}></i>
                </a>
            )
        })}
        {props.children}
        </div>
    )
}

export default SocialNav;
