import React from 'react';
import "../styles/PhotoContainer.scss";

const PhotoContainer = props => {

    const { category, text, idx, toggleModal } = props;

    return (
        <div className="photo-container" onClick={() => toggleModal(idx, category)}>
            <img
                src={require(`../images/${category}/${idx < 10 ? "0" : ""}${idx}.jpg`).default}
                alt={text}           
            />
        {props.children}
        </div>
    )
}

export default PhotoContainer;
