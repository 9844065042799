import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import Hamburger from "../components/Hamburger.js";
import "../styles/Nav.scss";

const Nav = props => {

    const { headerHeight } = props;

    const location = useLocation();

    const [headerClass, setHeaderClass] = useState("nav");

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [toggled, handleToggle] = useState(false);

    const listenScrollEvent = (event) => {
        return setHeaderClass(window.scrollY > 270 && window.innerWidth > 798 ? "scroll-nav" : "nav");
    }

    useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
        window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const listenResizeEvent = (event) => {
        if (window.innerWidth < 799 && window.innerWidth !== windowWidth) {
            handleToggle(false);
            setWindowWidth(window.innerWidth);
        }

        if (window.innerWidth > 798 && toggled) {
            handleToggle(false)
        }
    }

    useEffect(() => {
    window.addEventListener('resize', listenResizeEvent);

    return () =>
        window.removeEventListener('resize', listenResizeEvent);
    });

    const pages = [
        {
            name: "home",
            path: "/"
        },
        {
            name: "about",
            path: "/about"
        },
        {
            name: "shows",
            path: "/shows"
        },
        {
            name: "media",
            path: "/media"
        },
        {
            name: "store",
            path: "/store"
        },
        {
            name: "press",
            path: "/press"
        },
        {
            name: "contact",
            path: "/contact"
        }
    ];

    return (
        <nav
            className={`${headerClass}${toggled ? " nav-toggled" : ""}`}
        >
            <h1>The Angelus</h1>
            <Hamburger
                toggled={toggled}
                handleToggle={handleToggle}
                headerHeight={headerHeight}
            />
            <ul>
                {
                    pages.map((page) => (
                        
                        <li className={location.pathname === page.path ? "nav-active nav-link" : "nav-link"} key={page.name} onClick={() => handleToggle(!toggled)}>
                            <Link to={page.path}>
                                {page.name}
                            </Link>
                        </li>
                    ))
                }
            </ul>
            {props.children}
        </nav>
    )
}

export default Nav;
