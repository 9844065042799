import React, { useEffect , useRef } from 'react';
import "../styles/Header.scss";
import AngelusLogo from "../images/angelus_logo.png";

const Header = props => {

    const { setHeaderHeight } = props;

    const headerRef = useRef(null);

    const listenResizeEvent = (event) => {
        setHeaderHeight(headerRef.current.clientHeight);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setHeaderHeight(headerRef.current.clientHeight);
        }, 150);
        return () => clearTimeout(timer);
      }, [setHeaderHeight]);

    useEffect(() => {
    window.addEventListener('resize', listenResizeEvent);

    return () =>
        window.removeEventListener('resize', listenResizeEvent);
    });

    return (
        <div className="header" ref={headerRef}>
            <img className="img-shadow" src={AngelusLogo} alt="The Angelus logo."/>
           {props.children}
        </div>
    )
}

export default Header;