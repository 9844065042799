import React from 'react';
import "../styles/Main.scss";

const Main = props => {
    return (
        <div className="main">
           {props.children}
        </div>
    )
}

export default Main;