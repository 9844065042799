import React from 'react';
import "../styles/Hamburger.scss";

const Hamburger = props => {

    const { toggled, handleToggle, headerHeight } = props;

    let baseHeight = window.innerWidth > 385 ? 34 : 30;

    return (
        <div
            className={toggled ? "hamburger toggled-on" : "hamburger toggled-off"} onClick={() => handleToggle(!toggled)}
            style={{ top: `${(headerHeight - baseHeight) / 2}px` }}
        >
            <div className="bar top-bar"></div>
            <div className="bar middle-bar"></div>
            <div className="bar hidden-bar"></div>
            <div className="bar bottom-bar"></div>
           {props.children}
        </div>
    )
}

export default Hamburger;