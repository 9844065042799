import React, { useState, useEffect } from 'react';
import SubNav from "../components/SubNav.js";
import "../styles/Press.scss";

const Press = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const press = [
        {
            id: 0,
            album: "Young Birds/The Weeping Song EP",
            title: "NPR Music",
            subtitle: "Viking's Choice Music Picks",
            source: "https://www.npr.org/sections/allsongs/2019/10/30/774143962/vikings-choice-sound-foraging-ambient-dank-drone-and-spanish-dance-pop",
            date: "30 October 2019",
            text: "Redemption often comes at the hands of something bigger than yourself, but as The Angelus' soul-rattling doom-gaze reminds us, the love of young children will make you humble.",
            category: "praise"
        },
        {
            id: 1,
            title: "Brooklyn Vegan",
            subtitle: "Staff Music Picks",
            source: "https://www.brooklynvegan.com/22-new-songs-out-today-19/",
            date: "30 October 2019",
            text: "Even if you aren’t familiar with The Angelus by name, you may recognize Emil Rapstine as one of the core guest vocalists on Dead To A Dying World’s great new album Elegy and a member of their touring band. They’ve now got a new single, “The Young Birds,” which is a very appealing dose of dark psychedelic rock, and it comes back with a well-executed Nick Cave and the Bad Seeds cover.",
            category: "praise"
        },
        {
            id: 2,
            title: "Central Track",
            subtitle: "Song of the Day",
            source: "https://www.centraltrack.com/song-of-the-day-the-angelus-the-young-birds/",
            date: "30 October 2019",
            text: "Backed by a cover of Nick Cave and the Bad Seeds' “The Weeping Song,” the trio has unleashed “The Young Birds,” an inspired, five-minute slice of folky doom.",
            category: "praise"
        },
        {
            id: 3,
            album: "There Will Be No Peace",
            title: "NPR Music",
            subtitle: "Video Premiere",
            source: "https://www.npr.org/event/music/529674967/the-angelus-the-other-side-of-the-mountain-addresses-the-hope-in-darkness/",
            date: "24 May 2017",
            text: ". . . reaches across humankind with a body-rattling, desert-swept heft . . .",
            category: "praise"
        },
        {
            id: 4,
            title: "Blurt Magazine",
            subtitle: "Track Premiere",
            source: "http://blurtonline.com/news/track-premiere-angelus-interceding/",
            date: "6 December 2016",
            text: ". . . a remarkable album, sonically fluid and lyrically awash in emotional certitude and resilience.",
            category: "praise"
        },
        {
            id: 5,
            title: "Noisey",
            subtitle: "Album Premiere",
            source: "https://noisey.vice.com/en_us/article/vvv4dj/listen-to-the-angeluss-haunting-southern-gothic-post-rock-odyssey",
            date: "13 January 2017",
            text: ". . . epic, brutally timely, and, indeed, haunting.",
            category: "praise"
        },
        {
            id: 6,
            title: "Echoes and Dust",
            subtitle: "Review",
            source: "http://echoesanddust.com/2017/01/the-angelus-there-will-be-no-peace/",
            date: "21 January 2017",
            text: "The Angelus seem to have not only captured the mood of these times, they’re also walking it through the streets with a bell round its neck for everyone to see.",
            category: "praise"
        },
        {
            id: 7,
            title: "Hellride Music Superstore",
            subtitle: "Review",
            source: "http://www.hellridemusicforums.com/forum/showthread.php?p=427382#post427382",
            date: "25 February 2017",
            text: ". . . a work of extraordinary melancholic beauty and pure, aural power.",
            category: "praise"
        },
        {
            id: 8,
            title: "Tiny Mix Tapes",
            subtitle: "Review",
            source: "https://www.tinymixtapes.com/cerberus/angelus-there-will-be-no-peace",
            date: "13 February 2017",
            text: ". . . an epic in the style of Homer.",
            category: "praise"
        },
        {
            id: 9,
            title: "The Vinyl District",
            subtitle: "Review (A-)",
            source: "http://www.thevinyldistrict.com/storefront/2017/01/graded-on-a-curve-the-angelus-there-will-be-no-peace/",
            date: "3 January 2017",
            text: "The Angelus have dished a leaner work that’s power sneaks up and grabs the ear.",
            category: "praise"
        },
        {
            id: 10,
            title: "Modern Vinyl",
            subtitle: "Review",
            source: "http://modern-vinyl.com/2017/02/06/vinyl-review-the-angelus-there-will-be-no-peace/",
            date: "6 February 2017",
            text: "Achingly gorgeous . . .",
            category: "praise"
        },
        {
            id: 11,
            title: "Aleteia",
            subtitle: "Review",
            source: "https://aleteia.org/2017/06/02/metal-band-the-angelus-finds-hope-in-the-darkness/",
            date: "2 June 2017",
            text: ". . . the narrator is a kind of ontological itinerant, a pilgrim who moves through the bleakness of the world with his eyes fixed on the sun.",
            category: "praise"
        },
        {
            id: 12,
            title: "Divide and Conquer Music",
            subtitle: "Review 4/5",
            source: "http://www.divideandconquermusic.com/indie-music-album-reviews/the-angelus-there-will-be-no-peace",
            date: "2 October 2017",
            text: ". . . this darkness of language and the immense musical talents of this trio combine to make a record that is rather awe inspiring.",
            category: "praise"
        },
        {
            id: 13,
            album: "On A Dark & Barren Land",
            title: "Pop Matters",
            subtitle: "Review 6/10",
            source: "http://www.popmatters.com/review/154213-the-angelus-on-a-dark-barren-land/",
            date: "13 February 2012",
            text: "We might be looking at the birth of a new genre here: dirge-rock?",
            category: "praise"
        },
        {
            id: 14,
            title: "Dallas Observer",
            subtitle: "Review",
            source: "http://www.dallasobserver.com/music/the-angelus-6423771",
            date: "3 November 2011",
            text: "Few records conjure up such specific imagery as Denton act The Angelus do on their long-awaited debut album, On A Dark & Barren Land. Listening to it, you could swear that lead singer Emil Rapstine has lived his life alone on a desolate, rural landscape in the l800s.",
            category: "praise"
        },
        {
            id: 15,
            title: "Dallas Observer",
            subtitle: "w/Le Leek Electrique & Clint Niosi at Dan's Silvereaf",
            source: "http://www.dallasobserver.com/music/the-angelus-le-leek-electrique-clint-niosi-dans-silverleaf-7-7-12-7058065",
            date: "9 July 2012",
            text: "Lead singer Emil Rapstine and drummer Justin Evans harmonized perfectly through songs like Turned To Stone and Screaming Bloody Murder.",
            category: "live"
        },
        {
            id: 16,
            title: "Dallas Observer",
            subtitle: "w/Thee Silver Mt. Zion Memorial Orchestra at Sons of Hermann Hall",
            source: "http://www.dallasobserver.com/music/thee-silver-mt-zion-memorial-orchestras-long-form-drone-at-sons-of-hermann-hall-7049003",
            date: "13 February 2012",
            text: "Dallas trio The Angelus played 45 minutes of hymnal slowcore. They were a wise fit, setting the tone for an evening where brevity would have cheapened the vibe.",
            category: "live"
        },
        {
            id: 17,
            title: "The Squirreling Podcast",
            subtitle: "Episode #6 - Emil Rapstine",
            source: "https://soundcloud.com/squirrelingpodcast/squirreling-podcast-episode-6",
            date: "22 July 2017",
            text: "I got the chance to meet and sit down with Emil and it was a great pleasure to have him on the podcast . . . his band is fantastic.",
            category: "interviews"
        },
        {
            id: 18,
            title: "New Noise Magazine",
            subtitle: "Wellness Spotlight Featuring The Angelus",
            source: "https://newnoisemagazine.com/wellness-august-burns-red-football/",
            date: "21 July 2017",
            text: "Describing their sound as “hymnal slowcore” and “gothspell,” The Angelus . . . bring Southern Gothic to an aural plane.",
            category: "interviews"
        },
        {
            id: 19,
            title: "Shoot The Shred podcast",
            subtitle: "Episode 44: Emil Rapstine (The Angelus)",
            source: "https://www.mixcloud.com/shoottheshred/ep-44-emil-rapstine-the-angelus/",
            date: "30 January 2017",
            text: "Erik Hall is joined by Emil Rapstine, vocalist/guitarist from the Dallas, TX based trio, The Angelus.",
            category: "interviews"
        },
        {
            id: 20,
            title: "D Magazine",
            subtitle: "Questions With: Emil Rapstine of The Angelus",
            source: "https://www.dmagazine.com/arts-entertainment/2013/04/questions-with-emil-rapstine-of-the-angelus-on-why-he-stopped-drinking-and-music-from-sub-saharan-africa/",
            date: "18 April 2013",
            text: ". . . The Angelus has been described as “beautiful, bearded Texan folk music,” “dark yet melodic and chiming,” and “equal parts literate and creative.” Their unique droning, melancholic sound has earned them quite a following in the local music scene.",
            category: "interviews"
        },
        {
            id: 21,
            title: "Dallas Observer",
            subtitle: "The Angelus on Genesis, Whitney Houston and Slint",
            source: "http://www.dallasobserver.com/music/the-angelus-on-genesis-whitney-houston-and-slint-7078701",
            date: "25 July 2012",
            text: "The three members of The Angelus took time out and shared their embarrassing and non-embarrassing musical firsts.",
            category: "interviews"
        }
    ]

    const [contentIdx, setNavContent] = useState(0);

    const renderContent = () => {
        let category = "";

        switch(contentIdx) {
            case 0:
              category = "praise"
              break;
            case 1:
              category = "live"
              break;
            case 2:
              category = "interviews"
              break;
            default:
              category = "praise"
          }

        return press
        .filter(article => article.category === category)
        .map(article => {
            return (
            <React.Fragment key={article.id}>
                <h2>{article?.album}</h2>
                <h3>{article.title}</h3>
                <p className="source-date">
                    <a href={article.source} target="_blank" rel="noreferrer noopener">{article.subtitle}</a>
                    <span>&mdash;{article.date}</span>
                </p>
                <p>“{article.text}”</p>
            </React.Fragment>
            )
        })
    }

    return (
        <div className="press">
            <h2 className="mobile-title">Press</h2>
            <SubNav
                navLinks={["praise", "live", "interviews"]}
                setNavContent={setNavContent}
            />
            {renderContent()}
           {props.children}
        </div>
    )
}

export default Press;