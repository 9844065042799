import React, { useEffect } from 'react';
import BandsInTown from "../components/BandsInTown.js";

const Shows = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="shows">
            <h2 className="mobile-title">Shows</h2>
            <BandsInTown />
           {props.children}
        </div>
    )
}

export default Shows;