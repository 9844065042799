import React, { useEffect } from 'react';
import AngelusBand from "../images/angelusband2021.jpg";

const About = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="about">
            <h2 className="mobile-title">About</h2>
            <img className="float-left text" src={AngelusBand} alt="The Angelus in 2021 from left to right: Emil Rapstine, Justin Evans, and Justin Ward." title="The Angelus in 2021 from left to right: Emil Rapstine, Justin Evans, and Justin Ward." />
            <p>
                In 2017, <strong>The Angelus</strong> gave us their second full-length offering, <a href="https://www.vice.com/en/article/vvv4dj/listen-to-the-angeluss-haunting-southern-gothic-post-rock-odyssey" target="_blank" rel="noreferrer noopener">'There Will Be No Peace'</a>, a foreboding and despairing narrative told through carefully interwoven compositions of dark hymnal doom. Pretty. Bleak. A pre-apocalyptic telling of coming times that garnered much <a href="https://www.npr.org/2017/05/24/529674967/the-angelus-the-other-side-of-the-mountain-addresses-the-hope-in-darkness" target="_blank" rel="noreferrer noopener">critical acclaim</a>.
            </p>
            <p>
                Following the success of the album, bandleader <strong>Emil Rapstine</strong> was invited to record with the post-doom, atmospheric black metal band <a href="https://www.brooklynvegan.com/review-dead-to-a-dying-worlds-vast-new-lp-elegy-ft-jarboe-thor-harris-more/" target="_blank" rel="noreferrer noopener">Dead To A Dying World</a>, alongside such luminary guests as <strong>Jarboe</strong> (ex-SWANS) and <strong>Thor Harris</strong> (ex-SWANS). Emil subsequently spent the summer <a href="https://www.brooklynvegan.com/dead-to-a-dying-world-played-an-entrancing-show-at-saint-vitus-review/" target="_blank" rel="noreferrer noopener">touring with the band</a> as their album <strong>Elegy</strong> (<em>Profound Lore</em>) was met with one stellar review after another.
            </p>
            <p>
                Now a full-time member of Dead To A Dying World, Emil has not put The Angelus on the back burner. 2019 saw the release of their original song <a href="https://www.npr.org/sections/allsongs/2019/10/30/774143962/vikings-choice-sound-foraging-ambient-dank-drone-and-spanish-dance-pop" target="_blank" rel="noreferrer noopener">"The Young Birds"</a> and, a cover of <strong>Nick Cave &amp; The Bad Seeds'</strong> <a href="https://www.youtube.com/watch?v=FzzG6oeP86c&feature=youtu.be" target="_blank" rel="noreferrer noopener">"The Weeping Song"</a>. Now the band has completed their next full-length album, <strong>"Why We Never Die"</strong>.
            </p>
            <p>
                "Why We Never Die" was recorded by <a href="https://www.alexbhore.com/" target="_blank" rel="noreferrer noopener">Alex Bhore</a> (formerly of This Will Destroy You) in Dallas, TX at Elmwood Recording, which belongs to Grammy Award winning producer <strong>John Congleton</strong> (SWANS, Chelsea Wolfe, St. Vincent, Angel Olsen). The album was mastered by <a href="http://www.sarahregister.com/" target="_blank" rel="noreferrer noopener">Sarah Register</a> (Protomartyr, Horse Lords, Lower Dens).
            </p>
            <p>
                The Angelus take their name from the <a href="https://en.wikipedia.org/wiki/The_Angelus_(painting)" target="_blank" rel="noreferrer noopener">painting by Jean-François Millet</a>, inspired by the feeling of lonesome supplication the painting conveys.
            </p>
            {props.children}
        </div>
    )
}

export default About;