import React, { useState } from 'react';
import "../styles/SubNav.scss";

const SubNav = props => {

    const { navLinks, setNavContent } = props;

    const [activeLink, setActiveLink] = useState(navLinks[0]);

    const updateContent = (idx) => {
        setActiveLink(navLinks[idx]);
        setNavContent(idx)
    }

    return (
        <nav className="subnav">
            <ul>
                {
                    navLinks.map((navLink, idx) => (
                        <li className={activeLink === navLink ? "subnav-active subnav-link" : "subnav-link"} key={navLink} onClick={() => updateContent(idx)}>
                            {navLink}
                        </li>
                    ))
                }
            </ul>
            {props.children}
        </nav>
    )
}

export default SubNav;