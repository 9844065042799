import React, { useState } from 'react';
import "../styles/Modal.scss";

const Modal = props => {

    const { toggleModal, changePhoto, category, firstCatLength, photoIndex, photos, isModalOpen, handleChildClick } = props;

    const [showCaret, handleShowCaret] = useState("");

    console.log(firstCatLength)

    return (
        <div className={`modal${isModalOpen ? " modal-show" : ""}`}>
            <div className="modal-overlay" onClick={() => toggleModal(0, "publicity")}>
                <div className="modal-content" onClick={handleChildClick}>
                    <img
                        className="modal-image"
                        src={require(`../images/${category}/${photoIndex < 10 ? "0" : ""}${photoIndex}.jpg`).default}
                        alt={photos[category === "live" ? photoIndex + firstCatLength : photoIndex].text}
                    />
                    <div className="modal-caption">{photos[category === "live" ? photoIndex + firstCatLength : photoIndex].caption}</div>
                    <div className="modal-close" onClick={() => toggleModal(0, "publicity")}>&times;</div>
                    <div
                        className="left-caret"
                        onMouseEnter={() => handleShowCaret("left")}
                        onMouseLeave={() => handleShowCaret("")}
                        onClick={() => changePhoto(-1)}
                    >
                        <span className={showCaret === "left" ? "show-caret" : ""}>&lsaquo;</span>
                    </div>
                    <div
                        className="right-caret"
                        onMouseEnter={() => handleShowCaret("right")}
                        onMouseLeave={() => handleShowCaret("")}
                        onClick={() => changePhoto(+1)}
                    >
                        <span className={showCaret === "right" ? "show-caret" : ""}>&rsaquo;</span>
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default Modal;