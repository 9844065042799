import React, { useState, useEffect } from 'react';
import SubNav from "../components/SubNav.js";
import Music from "../components/Music.js";
import Merch from "../components/Merch.js";

const Store = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const content = [<Music />, <Merch />]

    const [contentIdx, setNavContent] = useState(0);

    return (
        <div className="store">
            <h2 className="mobile-title">Store</h2>
            <SubNav
                navLinks={["music", "merch"]}
                setNavContent={setNavContent}
            />
            {content[contentIdx]}
            <p className="large-font center">
                <a href="https://theangelus.bandcamp.com/" target="_blank" rel="noreferrer noopener">Full store</a>
            </p>
           {props.children}
        </div>
    )
}

export default Store;