import React from 'react';
import "../styles/VideoContainer.scss";

const VideoContainer = props => {

    const { source, title, videoSize } = props;

    return (
        <div className={`video-container ${videoSize}`}>
            <iframe
                src={`https://www.youtube.com/embed/${source}`}
                title={title}
                allowFullScreen
            >
            </iframe>
            {props.children}
        </div>
    )
}

export default VideoContainer;