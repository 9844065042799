import React, { useEffect } from 'react';
import CircleLogo from "../images/circlelogo.png";

const Contact = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="contact">
            <h2 className="mobile-title">Contact</h2>
            <p>For all press information, please <a href="mailto:desertrecordlabel@gmail.com">contact Desert Records</a>.</p>
            <p>For all other inquiries, please <a href="mailto:theangelusband@gmail.com">email us</a>.</p>
            <img className="center half img-shadow" src={CircleLogo} alt="The Angelus cirular logo."/>
           {props.children}
        </div>
    )
}

export default Contact;