import React, { useState, useEffect } from 'react';
import SubNav from "../components/SubNav.js";
import Videos from "../components/Videos.js";
import Photos from "../components/Photos.js";

const Media = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const content = [<Videos />, <Photos />]

    const [contentIdx, setNavContent] = useState(0);

    return (
        <div className="media">
            <h2 className="mobile-title">Media</h2>
            <SubNav
                navLinks={["videos", "photos"]}
                setNavContent={setNavContent}
            />
            {content[contentIdx]}
           {props.children}
        </div>
    )
}

export default Media;