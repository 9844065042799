import React, { useEffect } from 'react';
import VideoContainer from "../components/VideoContainer.js";
import MusicModule from "../components/MusicModule.js";
import WhyWeNeverDie from "../images/why_we_never_die.jpg";

const Home = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
            <VideoContainer
                source="Kr-Sb9at21U"
                title="The Angelus - Why We Never Die (teaser video)"
                videoSize="video-container-16x9"
            />
            <h2 className="center">Why We Never Die</h2>
            <h3 className="center">
                New album out now!
            </h3>
            <img className="center splash" src={WhyWeNeverDie} alt="An album cover featuring a peacock, the band name The Angelus, and the album title, Why We Never Die." />
            <p>
                In a dim world, with death our only guarantee, <strong>The Angelus</strong> returns with their third full-length offering <strong>'Why We Never Die'</strong>. An album full of songs both powerfully engulfing and mesmerizingly intimate, the album’s title alludes to one's constant rebirth through the creation of music and to the band’s hope to transcend the impending eventuality of death when all that remains is the music, and art becomes artifact. The cover art, featuring a highly stylized rendering of a white peacock resembling the traditional description of the phoenix, reinforces the hope that rebirth through creation allows us to live forever in the material world. The Dallas, Texas trio consists of <strong>Emil Rapstine</strong> (<strong>Dead To A Dying World</strong>) on guitar and vocals, accompanied by his stalwart co-conspirator <strong>Justin Evans</strong> on drums and backing vocals, and their newest accomplice <strong>Justin Ward</strong> on bass. The album, saturated with plaintive, intoning, and harmonizing vocals, despairing lyrics and darkly droning guitar, draws from post-rock, doom, folk, and dark psychedelic rock. The  pleading voices and resounding chords here do not decay because they belong to any ears open to hear them as they reverberate for eternity.
            </p>
            <h2 className="center">
                Buy now!
            </h2>
            <div className="center">
                <MusicModule
                    source={"3928851053"}
                    title={"Why We Never Die"}
                />
            </div>
           {props.children}
        </div>
    )
}

export default Home;
