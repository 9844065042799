import React, { useState } from 'react';
import Modal from '../components/Modal.js';
import PhotoContainer from "../components/PhotoContainer.js";
import "../styles/Photos.scss";

const Photos = props => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [category, setCategory] = useState("publicity");

    const photos = [
        {
            category: "publicity",
            text: "Emil Rapstine in a forest.",
            caption: "Photo by Erin Rambo"
        },
        {
            category: "publicity",
            text: "Emil Rapstine in a forest.",
            caption: "Photo by Erin Rambo"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Michael Briggs"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Michael Briggs"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Victor Nathans"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Victor Nathans"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Victor Nathans"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Victor Nathans"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Victor Nathans"
        },
        {
            category: "publicity",
            text: "The Angelus band members.",
            caption: "Photo by Victor Nathans"
        },
        {
            category: "publicity",
            text: "The Angelus band members in the studio.",
            caption: "Photo by Michael Briggs"
        },
        {
            category: "publicity",
            text: "The Angelus band members in the studio.",
            caption: "Photo by Michael Briggs"
        },
        {
            category: "live",
            text: "The Angelus band members playing live.",
            caption: "Photo by David Gilbert"
        },
        {
            category: "live",
            text: "Emil Rapstine playing live.",
            caption: "Photo by David Gilbert"
        },
        {
            category: "live",
            text: "Ryan Wasterlain playing live.",
            caption: "Photo by David Gilbert"
        },
        {
            category: "live",
            text: "Justin Evans playing live.",
            caption: "Photo by David Gilbert"
        },
        {
            category: "live",
            text: "A closeup of Emil Rapstine's boot on a guitar pedal.",
            caption: "Photo by David Gilbert"
        },
        {
            category: "live",
            text: "Emil Rapstine playing live, with Ryan Wasterlain in the background.",
            caption: "Photo by David Gilbert"
        },
        {
            category: "live",
            text: "The Angelus band members playing live.",
            caption: "Photo by Jenna B"
        }
    ]

    const toggleModal = (i, cat) => {
        setPhotoIndex(i);
        setCategory(cat);
        setIsModalOpen(!isModalOpen);
        if (isModalOpen) {
            document.body.classList.remove('modal-open');
        } else {
            document.body.classList.add('modal-open');
        }
        
      };;

    const handleChildClick = (e) => {
        e.stopPropagation()
    }

    const renderPhotos = (cat) => {

        return photos
        .filter(image => image.category === cat)
        .map((image, i) => (
            <PhotoContainer
                key={i}
                idx={i}
                category={image.category}
                text={image.text}
                toggleModal={toggleModal}
            />
        ))
    }

    const changePhoto = (op) => {

        let index = 0;

        let cat = category === "live" ? "publicity" : "live";
        let changeCat = false;

        if (photoIndex === 0 && op === -1) {
            index = photos.filter(photo => photo.category === cat).length -1;
            changeCat = true;
        } else if (photoIndex === photos.filter(photo => photo.category === category).length -1 && op === +1) {
            index = 0;
            changeCat = true;
        } else {
            index = photoIndex + op;
        }
        
        if (changeCat) {
            setCategory(category === "live" ? "publicity" : "live");
        }

        setPhotoIndex(index);

    }

    return (
        <div className="photos">
            <Modal
                category={category}
                firstCatLength={photos.filter(photo => photo.category === "publicity").length}
                photoIndex={photoIndex}
                changePhoto={changePhoto}
                isOpen={isModalOpen}
                photos={photos}
                toggleModal={toggleModal}
                isModalOpen={isModalOpen}
                handleChildClick={handleChildClick}
            >
            </Modal>   
            <h2>Publicity Photos</h2>
            <div className="photo-row">
                {renderPhotos("publicity")}
            </div>
            <h2>Live Photos</h2>
            <div className="photo-row">
                {renderPhotos("live")}
            </div>            
            {props.children}
        </div>
    )
}

export default Photos;
