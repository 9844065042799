import React from 'react';
import SocialNav from "../components/SocialNav.js";
import "../styles/Footer.scss";

const Footer = props => {

    const year = new Date().getFullYear();

    const icons = [
        {
            id: 0,
            iconClass: "fab fa-facebook-f",
            source: "http://www.facebook.com/theangelusband"
        },
        {
            id: 1,
            iconClass: "fab fa-twitter",
            source: "http://twitter.com/theangelusband"
        },
        {
            id: 2,
            iconClass: "fab fa-soundcloud",
            source: "https://soundcloud.com/the-angelus"
        },
        {
            id: 3,
            iconClass: "fab fa-youtube",
            source: "https://www.youtube.com/channel/UC5DBuWkUmHEj-Xrke8cbXLw"
        },
        {
            id: 4,
            iconClass: "fab fa-instagram",
            source: "http://www.instagram.com/theangelusband"
        },
        {
            id: 5,
            iconClass: "fab fa-spotify",
            source: "https://open.spotify.com/artist/0QFTjY6oIcuyJZEjs7NjjM"
        },
        {
            id: 6,
            iconClass: "fab fa-bandcamp",
            source: "https://theangelus.bandcamp.com/"
        }

    ]

    return (
        <div className="footer">
            <SocialNav
                icons={icons}
            />
            <p>
                © Copyright {year} The Angelus
            </p>
           {props.children}
        </div>
    )
}

export default Footer;